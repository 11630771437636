import React from 'react';
import Post from '../components/post';
import EvenFinancialWidget from '../components/EvenFinancialWidget';
import LazyLoadImage from '../components/LazyLoadImage';


const UnderstandingDebtConsolidationPage = () => {
    const article = {
        id: '26bfdcb0-379b-574b-a0a8-de3e7839e7bd',
        title: 'Understanding Debt Consolidation: What It Does and How It Can Help',
        slug: '/understanding-debt-consolidation/',
        date: '2018-01-24T18:52:44.000Z',
        modified: '2021-10-29T17:05:04.000Z',
        excerpt: 'Millions of Americans are troubled with crippling amounts of debt every day. Learn more about how this debt can be overcome with the right strategy and consolidation.',
        featured_image: {
            source_url: '/media/coin.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 12,
        content: (
            <>
                <h2 id="are-you-in-debt">Are you in debt?</h2>
                <p>You&rsquo;re certainly not alone.</p>
                <p>
                    According to the Federal Reserve, the total household debt in America rang up to $12.35 trillion at the end of Q3 in 2016, while
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=understanding-debt-consolidation&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    balances climbed $18 billion by the end of Q2.
                </p>
                <p>How much of that debt are you carrying?</p>
                <p> If you&rsquo;re tired of being part of the statistics, it&rsquo;s time to make a plan to get yourself out of debt.</p>
                <p>
                    Being debt free means more than just a zero
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=understanding-debt-consolidation&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    balance and a positive net worth.
                </p>
                <p>It means no stress about paying the bills and having complete control of your finances.</p>
                <p>
                    It means having enough to pay expenses that arise and having enough money to build up a hefty
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=understanding-debt-consolidation&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    {' '}
                    account.
                </p>
                <p>To become debt free, you need a plan that works for your financial situation. </p>
                <LazyLoadImage src="/media/financial-situation-of-american-families-1.png" alt="Financial Situation of American Families" />
                <p>There are options available, including debt consolidation.</p>
                <p>
                    But what is debt consolidation, and
                    {' '}
                    <a href="https://www.debtconsolidation.com/loans/is-it-bad-consolidate-debt/">is it right for you?</a>
                </p>
                <p style={{
                    fontSize: '1.71428571rem', lineHeight: '1.28571429em', fontWeight: 700, marginTop: '31.5917px', marginBottom: '5px', textAlign: 'center',
                }}
                >
                    See If You Qualify for a Personal&nbsp;Loan up&nbsp;to&nbsp;$250k
                </p>
                {' '}
                <EvenFinancialWidget url="https://embed.hifiona.com/ui/multi-product/index.html?partner=debtconsolidationembed&amp;access_token=628217c7-f4c2-4006-a2b1-3031b4c21272_8362ee6d-9497-4440-aaf2-8c6e5685f90b&amp;company_uuid=65d1a0bd-1cab-43c4-b755-2a6bff913a03&amp;productType=loan&amp;pw_fiona=true&amp;available_products=loan" slug="understanding-debt-consolidation" />
                <h2 id="what-every-consumer-needs-to-know-about-debt-consolidation">What every consumer needs to know about debt consolidation</h2>
                <p>As the term suggests, debt consolidation takes various debts—like those multiple credit card balances (ouch!)—and combines them into a single monthly payment, usually at a lower interest rate.</p>
                <p>Think of debt consolidation as &quot;getting all your debts under one roof,&quot; explains Kevin Gallegos, vice president of Phoenix operations at debt relief provider Freedom Financial Network.</p>
                <p>While it sounds like a good plan, debt consolidation is not a one-size-fits-all solution.</p>
                <p>&quot;Debt consolidation may not the best debt relief method for people who are unable to make minimum payments on current debt,&quot; says Gallegos.</p>
                <p>
                    Regardless of what the debt is—be it credit card debt,
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=understanding-debt-consolidation&amp;sub2=student-loan" rel="noopener noreferrer" target="_blank">student loan</a>
                    {' '}
                    debt, or even a car loan—it is getting in your way of building a secure future, it&rsquo;s in your best interest (no pun intended!) to pay it off as soon as possible.
                    {' '}
                </p>
                <p>
                    You want to be funding your
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=understanding-debt-consolidation&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    {' '}
                    and retirement accounts, not handing your money over to a lender or credit card company.
                </p>
                <p><strong>Looking for help?</strong></p>
                <ul className="list">
                    {' '}
                    <li>
                        The US Department of Justice has a&nbsp;
                        <a href="https://www.justice.gov/ust/list-credit-counseling-agencies-approved-pursuant-11-usc-111">list of credit counseling agencies that meet lofty legal standards</a>
&nbsp;(a good thing when it comes to trusting someone to recommend advice for your money).
                    </li>
                    {' '}
                    <li>
                        American Consumer Credit Counseling Professionals offers&nbsp;
                        <a href="http://www.consumercredit.com/">free credit counseling and initial support</a>
                        . Be sure to the visit the FAQ on their website and if interested, call their toll-free number, +1 (800) 769-3571, if you&rsquo;d like to be connected with a professional credit counselor.
                    </li>
                </ul>
                <h3>Debt consolidation is different from debt settlement</h3>
                <p>
                    Debt settlement involves paying off creditors with a lump sum—
                    <a href="/debt-settlement/">usually less than the full amount owed</a>
                    , as determined by a third party.
                </p>
                <p>Whole companies are dedicated to getting you and the original lenders to agree to new terms. </p>
                <p>The idea behind these debt settlement companies is that the status quo isn&rsquo;t working. </p>
                <p>You aren&rsquo;t able to pay your existing debt and thus, they&rsquo;ll lose out on all the money—because you truly could go broke.</p>
                <p>A debt settlement company gets both sides (you and the lender(s)) to agree to a new deal. </p>
                <p>You pay the debt settlement company, who takes a cut and makes sure the original lender gets their share.</p>
                <p>Debt settlement is a step before formally declaring bankruptcy, which has a huge and lasting impact on your credit score. Don&rsquo;t go there unless you&rsquo;re out of options.</p>
                <p>After you have crunched the numbers and taken a hard look at your budget, and discovered that you simply can&rsquo;t afford to pay your existing debt, then consider speaking with a licensed credit counselor (see the previous section).</p>
                <h3>There is a difference between good and bad debt</h3>
                <p>Owing money certainly isn&rsquo;t the best feeling in the world. In fact, it might lead you to think all debt is bad debt—one of the biggest debt myths of all.</p>
                <p><strong>The three&nbsp;worst&nbsp;kinds of debt:</strong></p>
                <ol className="list">
                    {' '}
                    <li>Credit card – insane interest rates eat away at your income (and life)</li>
                    {' '}
                    <li>Student – big numbers rack up without the lucrative careers to justify the cost</li>
                    {' '}
                    <li>Auto – vanity gets in the way of sanity</li>
                </ol>
                <p>
                    But take what&rsquo;s owed on your mortgage, for instance. That&rsquo;s &quot;good debt,&quot; explains Anthony Piccone, president and CEO of 7th Level Mortgage, based in Philadelphia, Pennsylvania—especially in light of&nbsp;
                    <a href="http://www.bankrate.com/mortgage.aspx">today&rsquo;s low-interest rates.</a>
                </p>
                <p>While a mortgage is still a debt, it&rsquo;s not the same as being saddled with high-interest college or graduate school loans.</p>
                <p>Besides the benefit of deducting mortgage interest on your tax returns each year, when adjusted for inflation, &quot;[a mortgage] is the cheapest debt you can have, if you must,&quot; Piccone says.</p>
                <p>Paying a mortgage means you&rsquo;re investing in your future. </p>
                <p>Paying a credit card bill? </p>
                <p>Not so much. </p>
                <p>That&rsquo;s why it&rsquo;s important to get rid of&nbsp;that&nbsp;debt as quickly as you can so you can focus on what matters most.</p>
                <h3>You have options for consolidating debt</h3>
                <p>
                    Not every debt consolidation solution requires taking out a
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=understanding-debt-consolidation&amp;sub2=personal-loan" rel="noopener noreferrer" target="_blank">personal loan</a>
                    .
                    {' '}
                </p>
                <p>
                    Depending on how much you owe, your credit score, and whether you own or rent your home, there are
                    {' '}
                    <a href="https://www.debtconsolidation.com/analyzing-debt-consolidation/">alternative debt consolidation options</a>
                    .
                </p>
                <h4>Transfer debt to a new credit card with 0% APR</h4>
                <p>If you have a credit card, you&rsquo;ve likely seen offers for low-to-no-interest balance transfers. </p>
                <p>A balance transfer takes the debt on one or more cards and moves it over to a single card so that you have one monthly payment.</p>
                <p>Balance transfers use your existing available credit line to combine your debt at a zero or low-interest rate. </p>
                <p>Because they don&rsquo;t take very long for approval—most are completed within 7 to 10 business days—balance transfers are one of the easiest ways to consolidate debt.</p>
                <p>They aren&rsquo;t completely risk-free, however, cautions Piccone.</p>
                <p>A higher credit card balance will have a negative impact on your credit score until you pay it off.</p>
                <p>Worse, if you still have a balance after the low-interest period ends and the double-digit interest rates return, you&rsquo;ll find yourself&nbsp;further&nbsp;behind the eight ball on debt again.</p>
                <p>The new credit card won&rsquo;t make the old &quot;over-spending&quot; problems go away.</p>
                <p>Keep in mind that banks and credit card companies are businesses—and businesses need to make money. Any new credit card provider isn&rsquo;t in the business of helping you out. </p>
                <p><LazyLoadImage src="/media/leading-credit-card-companies-2017-2.png" alt="Leading Credit Card Companies (2017)" /></p>
                <p>Unless you are sure you can pay off your balance transfer within the timeframe of the offer (usually a year or more, depending on the card), you might end up paying even more in the long run.</p>
                <p>Pay off and then cut up cards to remove temptation.</p>
                <p>Another thing to think about is canceling your credit cards after you pay them off. </p>
                <p>Consider keeping one or two for convenience, but if you&rsquo;ve got half a dozen cards, that&rsquo;s way too tempting. </p>
                <p>To avoid another cycle of debt in the future, you might want to cut yourself off from future spending sprees by—literally—cutting those cards up after canceling them.</p>
                <h4>Take out a personal loan</h4>
                <p>As described earlier, taking out a personal loan can be a smarter way to pay off debt. </p>
                <p>Why pay 20% interest when you can pay 10% instead?</p>
                <p>
                    Plus,
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=understanding-debt-consolidation&amp;sub2=personal-loans" rel="noopener noreferrer" target="_blank">personal loans</a>
                    {' '}
                    can also be approved and funded quickly.
                    {' '}
                </p>
                <p>But don&rsquo;t think this is money for nothing. </p>
                <p>Besides the interest rate, which is set based on your personal credit score, a personal loan will often come with &quot;origination fees&quot; that equal about 5% of the total loan. </p>
                <p>It costs money to get money.</p>
                <h4>To figure out if a personal loan is right for you, ask the following questions:</h4>
                <p><strong>1. What&rsquo;s my average APR (referring to credit card debt)?</strong></p>
                <p>If you don&rsquo;t know, stop and assess before considering a personal loan. </p>
                <p>You need to know this amount to figure out the right solution.</p>
                <p><strong>2. Do I pay off a portion of the principal each month?</strong></p>
                <p>If you only pay off the interest, and not a piece of the principal debt, then you need to reassess your ability to budget before exploring a personal loan. </p>
                <p>You want to pay off the principal debt as fast as your budget can afford.</p>
                <p><strong>3. Is my credit score between 300 and 649?</strong></p>
                <p>A &quot;bad&quot; credit score may mean that the interest rate for a personal loan won&rsquo;t be much better than the average one (from all your debts) you have now.</p>
                <p><strong>4. How much time before I pay off my credit card?</strong></p>
                <p>If you find that it&rsquo;s going to take you a few years to pay off your debt, and your personal loan interest rate is lower than the average APR now, then a loan may make sense. </p>
                <p>
                    You can use a&nbsp;
                    <a href="https://www.creditkarma.com/calculators/debtrepayment">debt payoff calculator</a>
&nbsp;to determine when you&rsquo;ll pay off multiple cards.
                </p>
                <h4>Borrow against your home equity</h4>
                <p>Just like you can take out a personal loan to help pay off debt more affordably, you can also borrow money from the bank based on the value of your home. </p>
                <p>
                    Sometimes referred to as a &quot;second mortgage,&quot;&nbsp;
                    <a href="https://www.nerdwallet.com/blog/mortgages/how-to-refinance-your-mortgage/">refinancing your mortgage</a>
&nbsp;can free up much-needed cash to pay off debt.
                </p>
                <p>For instance, you may own a home worth $100,000 according to the bank and market value.</p>
                <p>Based on the value of your home, the bank would loan you money at a lower interest rate than what you&rsquo;re paying on your existing debt.</p>
                <p>You&rsquo;d use that money to pay off the &quot;worst debts.&quot; </p>
                <p>Afterward, you would be in debt to the bank—but with a lower interest amount per month than before.</p>
                <p>Again, if you don&rsquo;t have the problem that you got you into this position of considering debt consolidation under control, then you should not lightly pursue this option. </p>
                <p>You don&rsquo;t want to expose the roof over your head unnecessarily. </p>
                <p>If your home&rsquo;s value dropped while you&rsquo;re paying off the loan, then you&rsquo;d still owe what you and bank agreed on when you refinanced, making this a risky move.</p>
                <h4>Borrow from friends and family</h4>
                <p>This is a very sensitive option for many emotional and personal reasons. </p>
                <p>The concept here is that you pay your friends and/or family a lower interest rate than what you&rsquo;re paying on your debts now.</p>
                <p>If you thought holidays were awkward now, then after borrowing money from your uncle, for example, you&rsquo;ll squirm at the thought of family.</p>
                <h2 id="4-signs-youre-the-perfect-fit-for-debt-consolidation">4 signs you&rsquo;re the perfect fit for debt consolidation</h2>
                <p>Now that you know what debt consolidation is, it&rsquo;s time to decide if you&rsquo;re a good fit for it or not.</p>
                <p>Consider what you&rsquo;ve already read, and then think if any of the following statements describe you:</p>
                <h3>1. You are frustrated with multiple loans and multiple payments</h3>
                <p>When you have several loans to manage, it can be frustrating to make all those individual payments and juggle payment dates. </p>
                <p>If you find yourself scrambling to make sure that&nbsp;everything&nbsp;is paid on time, debt consolidation can help. </p>
                <p>You get all of your loans together in one place, and then make only one payment for everything. </p>
                <p>It can help you avoid late payments and the difficulty of the schedule.</p>
                <h3>2. Multiple interest payments are slowing you down</h3>
                <p>Different loans come with different interest rates. </p>
                <p>Some rates (like credit cards) are much higher than others. </p>
                <p>High interest rates on some loans can slow down your overall repayment efforts. </p>
                <p>Consolidation allows you to bring everything together with one interest rate. </p>
                <p>Often, the rate is lower overall than what you see with your higher-rate debts individually. </p>
                <p>
                    You can&nbsp;
                    <a href="/6-steps-out-of-debt/">tackle your debt faster</a>
&nbsp;when you only have to make one payment and pay one interest rate each month.
                </p>
                <h3>3. You have the discipline to stop using debt</h3>
                <p>Before you engage in debt consolidation, you need to make sure you have the discipline to stop using debt. </p>
                <p>Too often, a consumer will consolidate debt, &quot;freeing up&quot; space on credit cards and with other loans. </p>
                <p>Once that happens, it&rsquo;s tempting to use the credit again for other purchases. </p>
                <p><LazyLoadImage src="/media/credit-card-debt-2017-3.png" alt="Credit Card Debt (2017)" /></p>
                <p>In these cases, debt consolidation results in a worse outcome, because you end up with more debt than ever.</p>
                <p>If you want to consolidate your debt effectively, you need to make sure you have the discipline to stop using debt to make purchases going forward.</p>
                <h3>4. It&rsquo;s hard to know where to start</h3>
                <p>When you have several loans, it&rsquo;s difficult to know where to start with your debt pay-down plan. </p>
                <p>This is where debt consolidation can help. </p>
                <p>You get all of your debt wrapped into one package, and then start paying it down. </p>
                <p>For the right person, debt consolidation can help you organize your efforts, and then get started.</p>
                <p>If any of these statements sound like you, then you might want to seriously consider looking further into debt consolidation. </p>
                <h2 id="the-biggest-myths-about-debt-consolidation">The biggest myths about debt consolidation</h2>
                <p>Are you on the fence about debt consolidation?</p>
                <p>Maybe it sounds like a promising tool to help you regain financial control and get out of debt, but you&rsquo;re worried about what you&rsquo;ve heard before.</p>
                <p>There is a lot of misinformation floating around about debt consolidation. </p>
                <p>&quot;Debt consolidation is the process of paying off two or more open loans with one new loan, thereby &lsquo;consolidating&rsquo; several accounts,&quot; explains James Poe, founder of Texas Retirement Specialists.</p>
                <p>Joe Parsons, a senior loan officer, mortgage broker and banker at PFS Funding in Dublin, Calif., explains that the main purposes of debt consolidation are:</p>
                <p>
                    1) to make your monthly payments easier to deal with by
                    {' '}
                    <a href="/demystified/">combining multiple loans into one loan</a>
                    {' '}
                    (and thus one monthly bill); and
                </p>
                <p>2) to lower your monthly payments by providing you with a single (ideally) lower-interest loan.</p>
                <p>Debt consolidation is designed to help you manage your payments, not put you in more debt.</p>
                <p>Here are four myths about debt consolidation and the truths behind them:</p>
                <h3>Myth #1: Debt consolidation is always a scam</h3>
                <p>Yes, there are scams around debt consolidation, and not all debt consolidation loans or plans save you money. </p>
                <p>But there are reputable companies that offer debt consolidation plans and/or loans, which can help ease your financial headaches and lower your monthly payments so you can free up money to pay for your everyday living expenses. </p>
                <p>&quot;Debt consolidation is a useful tool in the right situation, and can provide financial benefits to you when you choose a good loan from a low-rate lender,&quot; says Poe.</p>
                <h3>Myth #2: Debt consolidation and credit counseling are the same thing</h3>
                <p>Because debt consolidation and credit counseling are often mentioned in the same context, people often confuse the two. </p>
                <p>Credit counseling is exactly what it sounds like: counseling for someone with financial difficulties. </p>
                <p>
                    The credit counselor may provide budgeting advice,
                    {' '}
                    <a href="/debt-management/">a debt management plan</a>
                    {' '}
                    and negotiate with creditors for lower rates and pay off your bills on your behalf.
                </p>
                <h3>Myth #3: Debt consolidation will hurt my credit score</h3>
                <p>Using a debt consolidation loan to pay down other debts shouldn&rsquo;t significantly impact your credit score, experts say. </p>
                <p>Here&rsquo;s why: Only a small part of your credit score is derived from &quot;new credit&quot; — which looks at whether you&rsquo;ve opened up a few new credit lines recently — so opening this single new credit line shouldn&rsquo;t do much to change your credit score. </p>
                <p>(More important for your credit score is whether you make your monthly payments on that and other loans on time, as well as how much you owe and the length of your credit score.) </p>
                <p>
                    What&rsquo;s more, in some cases, consolidating your debt may improve your credit score, since &quot;you&rsquo;re converting score damaging revolving debt to score benign installment debt,&quot;&nbsp;
                    <a href="http://www.johnulzheimer.com/">says John Ulzheimer</a>
                    , the president of consumer education and SmartCredit.com.
                </p>
                <h3>Myth #4: Debt consolidation always leads to more debt</h3>
                <p>Debt consolidation is using one loan to pay off a bunch of other loans. </p>
                <p>So, assuming you get good terms on your new loan, there is no reason debt consolidation should lead to more debt. </p>
                <p>In fact, it can lead to lower total debt, says Parsons. </p>
                <p>But for some people, especially those who don&rsquo;t change their habits, it does lead to more debt, he says. </p>
                <p>&quot;If you reuse the existing, newly paid-off credit cards and end up back in the same debt, then you&rsquo;re actually in debt twice over,&quot; says Ulzheimer. </p>
                <p>To avoid going into more debt, once you&rsquo;ve paid off those credit cards with a debt consolidation loan, avoid using them as much as you can, says Parsons.</p>
                <h3>Are you ready to consolidate?</h3>
                <p>Debt consolidation isn&rsquo;t an end-all solution to debt problems. </p>
                <p><LazyLoadImage src="/media/debts-of-u-s-families-4.png" alt="Debts of U.S. Families" /></p>
                <p>You need to fix the habits that caused you to wind up in debt in the first place. </p>
                <p> This guide is a good place to start. </p>
                <p>If you find that you&rsquo;re a fit for debt consolidation, search around and find the method that meets all of your needs. </p>
                <p>
                    Maybe that means taking out a
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=understanding-debt-consolidation&amp;sub2=personal-loan" rel="noopener noreferrer" target="_blank">personal loan</a>
                    {' '}
                    or finding a debt consolidation company to help you through the process.
                </p>
                <p>Don&rsquo;t be afraid to take your time. </p>
                <p>Don&rsquo;t settle for one that is &quot;good enough&quot;—this is your budget and your lifestyle we&rsquo;re talking about.</p>
                <p>If any of you have experience with debt consolidation, please let us now in the comments below. </p>
                <p>Advice and tips are always welcome!</p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default UnderstandingDebtConsolidationPage;
